<template>
  <div class="divBox">
    <v-list
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      ref="table"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->
        <v-button text="新增" @click="handleAdd"></v-button>
        <!-- <v-button text="导出"></v-button> -->
      </template>
      <template #searchSlot>
        <!-- <area-select /> -->
        <v-select
          clearable
          :options="districtListOptions"
          v-model="searchParam.districtCode"
          label="选择区级"
          @change="districtCodeChange"
          @clear="districtCodeclear"
        />
        <v-select
          clearable
          :options="streetListOptions"
          v-model="searchParam.streetId"
          label="选择街道"
          @change="streetIdChange"
          @clear="streetIdClear"
        />
        <v-select
          clearable
          :options="structuringOptions"
          v-model="searchParam.structuringId"
          label="选择社区"
          @clear="structuringIdClear"
        />
        <v-select
          clearable
          :options="categoryList"
          v-model="searchParam.placeTypeCategoryId"
          label="选择一级分类"
          @change="getPlaceTypeOptions"
        />
        <community-select
          v-model="searchParam.communityId"
          label="选择园区"
          @change="getPlaceTypeOptions"
        />

        <v-select
          clearable
          v-if="searchParam.communityId && searchParam.categoryId"
          :options="placeTypeOptions"
          v-model="searchParam.placeTypeId"
          label="选择场地类型"
        />

        <v-select
          clearable
          :options="statusOptions"
          v-model="searchParam.status"
          label="选择开启状态"
        />

        <v-input label="场地名称" v-model="searchParam.name" clearable />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          type="text"
          @click="handleEdit(scope.row)"
        ></v-button>
        <v-button
          v-if="scope.row.status == '0'"
          text="开启"
          type="text"
          @click="handleOpenOrClose(scope.row, 1)"
        ></v-button>
        <v-button
          v-if="scope.row.status == '1'"
          text="关闭"
          type="text"
          @click="handleOpenOrClose(scope.row, 0)"
        ></v-button>
        <v-button
          text="删除"
          type="text"
          @click="handleDelete(scope.row)"
        ></v-button>
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  getStreetCommunityListUrl,
  getPlaceTypeUrl,
  placeListPagingUrl,
  getPlaceTypeListUrl,
  changePlaceStatus,
} from "./api.js";
import { getOption, createImgVNode } from "@/utils/utils.js";
import { placeStatus } from "./map.js";
import { Message, MessageBox } from "element-ui";

export default {
  name: "placeList",
  data() {
    return {
      searchParam: {
        placeTypeCategoryId: null,
        // communityId: null,
        placeTypeId: null,
        status: null,
        activityName: null,
        name: null,
        districtCode: null, //区域code
        streetId: null, //街道id
        structuringId: null, //社区id
      },
      placeTypeOptions: [],
      statusOptions: [],
      districtListOptions: [], //区域下拉列表
      streetListOptions: [], //街道下拉列表
      structuringOptions: [], //社区下拉列表
      categoryList: [],
      tableUrl: placeListPagingUrl,
      headers: [
        //                    {
        //                        prop: "id",
        //                        label: "场地Id",
        //                    },
        {
          prop: "districtName",
          label: "场地归属",
          formatter: (row, prop) => {
            let str =
              row.districtName +
              "-" +
              row.streetName +
              "-" +
              row.structuringName;
            return str;
          },
        },
        {
          prop: "name",
          label: "场地名称",
        },
        {
          prop: "picList",
          label: "场地图片",
          align: "center",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "address",
          label: "场地地点",
        },

        {
          prop: "area",
          label: "场地面积",
        },
        {
          prop: "count",
          label: "容纳人数",
        },
        {
          prop: "equip",
          label: "场地设备",
        },
        // {
        //   prop: "serverContent",
        //   label: "服务内容",
        // },
        {
          prop: "timeRegion",
          label: "可预定时间段",
        },

        {
          prop: "status",
          label: "状态",
          formatter: (row, prop) => {
            for (let i = 0; i < placeStatus.length; i++) {
              if (row[prop] === placeStatus[i].code) {
                return placeStatus[i].label;
              }
            }
          },
        },
      ],
    };
  },
  components: {},
  mounted() {
    this.getPlaceTypeOptions();
    this.getCategoryList();
    this.getStatusOptions();
    this.getStreetCommunityList();
  },
  computed: {},
  methods: {
    districtCodeclear() {
      //清空区级内容
      this.searchParam.districtCode = null;
      this.searchParam.streetId = null;
      this.searchParam.structuringId = null;
    },
    streetIdClear() {
      //清空街道内容
      this.searchParam.streetId = null;
      this.searchParam.structuringId = null;
    },
    structuringIdClear() {
      //清空社区内容
      this.searchParam.structuringId = null;
    },
    streetIdChange(value) {
      //选择街道change
      this.searchParam.structuringId = null;
      this.structuringOptions = [];
      this.streetListOptions.forEach((item, index) => {
        if (item.streetId == value) {
          item.list.forEach((item1) => {
            item1.label = item1.structuringName;
            item1.value = item1.structuringId;
            this.structuringOptions.push(item1);
          });
        }
      });
    },
    districtCodeChange(value) {
      //选择区级change
      this.searchParam.streetId = null;
      this.searchParam.structuringId = null;
      this.streetListOptions = [];
      this.allList.forEach((item, index) => {
        if (item.districtCode == value) {
          item.list.forEach((item1) => {
            item1.label = item1.streetName;
            item1.value = item1.streetId;
            this.streetListOptions.push(item1);
          });
          console.log(this.streetListOptions);
        }
      });
    },
    getStreetCommunityList() {
      //获取区县/街道/社区列表
      this.districtListOptions = [];
      this.$axios.get(`${getStreetCommunityListUrl}`).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.allList = res.data;
          res.data.forEach((item) => {
            item.label = item.districtName;
            item.value = item.districtCode;
            this.districtListOptions.push(item);
          });
        }
      });
    },
    getCategoryList() {
      let params = {};
      this.categoryList = [];
      this.$axios.get(`${getPlaceTypeUrl}`).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            item.label = item.categoryName;
            item.value = item.id;
            this.categoryList.push(item);
          });
        } else {
        }
      });
    },
    handleAdd() {
      this.$router.push({
        name: "placeEdit",
        query: { communityId: this.searchParam.communityId },
      });
    },

    handleEdit(data) {
      this.$router.push({
        name: "placeEdit",
        query: { placeId: data.id },
      });
    },
    handleDelete(data) {
      MessageBox.confirm("是否删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            placeId: data.id,
            status: 2,
          };
          this.$axios
            .post(`${changePlaceStatus}`, this.$qs.stringify(params))
            .then((res) => {
              if (res.code === 200) {
                Message.success("操作成功！");
                this.$refs.table.search();
              }
            });
        })
        .catch(() => {});
    },
    handleOpenOrClose(data, status) {
      MessageBox.confirm(status == "0" ? "是否关闭" : "是否开启", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            placeId: data.id,
            status: data.status == "0" ? 1 : 0,
          };
          this.$axios
            .post(`${changePlaceStatus}`, this.$qs.stringify(params))
            .then((res) => {
              if (res.code === 200) {
                Message.success("操作成功！");
                this.$refs.table.search();
              }
            });
        })
        .catch(() => {})
        .then(() => {
          let params = {
            id: data.id,
          };
          this.$axios
            .post(`${changePlaceStatus}`, this.$qs.stringify(params))
            .then((res) => {
              if (res.code === 200) {
                Message.success("操作成功！");
                this.$refs.list.search();
              }
            });
        })
        .catch(() => {});
    },

    getPlaceTypeOptions() {
      this.searchParam.placeTypeId = "";
      if (this.searchParam.communityId && this.searchParam.categoryId) {
        let params = {
          // communityId: this.searchParam.communityId,
          categoryId: this.searchParam.categoryId,
        };
        this.placeTypeOptions = [];
        this.$axios
          .get(`${getPlaceTypeListUrl}`, { params: params })
          .then((res) => {
            if (res.code === 200) {
              res.data.forEach((item) => {
                item.label = item.typeName;
                item.value = item.id;
                this.placeTypeOptions.push(item);
              });
            } else {
            }
          });
      }
    },

    getStatusOptions() {
      placeStatus.forEach((item) => {
        // 过滤掉删除
        if (item.code != "2") {
          item.value = item.code;
          this.statusOptions.push(item);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
